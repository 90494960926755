// app/components/ThemeSetter.tsx
'use client';

import { useEffect } from 'react';
import { useThemeContext } from './ThemeContext';

export function ThemeSetter({
  theme,
  renderHeader = true,
  renderFooter = true,
}: {
  theme: 'light' | 'dark';
  renderHeader?: boolean;
  renderFooter?: boolean;
}) {
  const { setTheme, setRenderFooter, setRenderHeader } = useThemeContext();

  useEffect(() => {
    setTheme(theme);
    setRenderHeader(renderHeader);
    setRenderFooter(renderFooter);

    if (theme === 'dark') {
      document.documentElement.style.setProperty('--themed-text-color', 'var(--white-primary)');
      // Set other dark theme variables...
    } else {
      document.documentElement.style.setProperty('--themed-text-color', 'var(--text-body)');
      // Set other light theme variables...
    }
    document.documentElement.style.setProperty('--global-theme', theme);
  }, [theme, setTheme, renderHeader, renderFooter, setRenderHeader, setRenderFooter]);

  return null;
}
